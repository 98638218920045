import BackgroundLineVideo from '../assets/background-line.jpg';
import '../styles/BackgroundLine.css';
import { useTranslation } from 'react-i18next';

function BackgroundLine() {

    const [t, i18n] = useTranslation("global");

    return(
        <div className='background-line'>
            <img src={BackgroundLineVideo} alt="background-line" />
            <div className='background-text'>
                <h1>{t("backgroundLine.header")}</h1>
                <h2>{t("backgroundLine.text")}</h2>
            </div>
        </div>
    );
}

export default BackgroundLine;