import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import '../styles/Navbar.css';
import Logo from '../assets/logo.png';
import { IoLogoYoutube } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { FaTiktok } from "react-icons/fa";

function CustomNavbar() {
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  }

  return (
    <Navbar expand="lg" className='navbar fixed-top'>
      <Container>
        <Navbar.Brand as={Link} to="/"><img src={Logo} alt="logo" width={100}/></Navbar.Brand>
        <select name="language" id="" className='language-selector' onChange={handleChangeLanguage}>
          <option value="en">EN</option>
          <option value="ru">RU</option>
          <option value="ge">GE</option>
        </select>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">{t("navbar.home")}</Nav.Link>
            <NavDropdown title={t("navbar.tours")} id="navbarScrollingDropdown">
              <NavDropdown.Item as={Link} to="/all-tours">{t("navbar.allTours")}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/experience">{t("navbar.exp")}</Nav.Link>
            <Nav.Link as={Link} to="/about">{t("navbar.about")}</Nav.Link>
            <Nav.Link as={Link} to="/contact">{t("navbar.contact")}</Nav.Link>
          </Nav>
          <Nav className='ms-auto'>
            <Nav.Link href='https://www.facebook.com/tvishitour/'><FaFacebook color='#4267B2' fontSize={20}/></Nav.Link>
            <Nav.Link href='https://www.instagram.com/tvishitour/'><FaInstagram color='#C13584' fontSize={20}/></Nav.Link>
            <Nav.Link href='https://www.youtube.com/channel/UCcCFjIMV5JU725c5KxlW0gA'><IoLogoYoutube color='red' fontSize={20}/></Nav.Link> 
            <Nav.Link href='https://www.tiktok.com/@tv1shitour'><FaTiktok/></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
