import '../styles/HomeAbout.css';
import Video from "../assets/busbridge.mp4";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function HomeAbout(){

    const [t, i18n] = useTranslation("global");
    
    return(
        <div className="homeAbout-body">
            <div className='about-body' data-aos="fade-up">
            <div className="about-text-section">
                <div className="about-header">
                    <h1>{t("homeAbout.header")}</h1>
                </div>
                <div className="about-text">
                    <p>{t("homeAbout.text")}</p>
                </div>
                <div className="about-btn">
                    <Link to="/about"><button>{t("homeAbout.readMore")}</button></Link>
                </div>
            </div>
            <div className="about-video">
                <video src={Video} type = "video/mp4" autoPlay loop muted></video>
            </div>
            </div>
        </div>
    );
}

export default HomeAbout;