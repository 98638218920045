import { TbWorldSearch } from "react-icons/tb";
import { TfiPencilAlt } from "react-icons/tfi";
import { IoCallSharp } from "react-icons/io5";
import "../styles/WorkingPlan.css";
import { useTranslation } from "react-i18next";

function WorkingPlan(){

    const [t, i18n] = useTranslation("global");

    return(
        <div className="working-plan">
                <div className="working-plan-cards" data-aos="fade-up">
                    <div className="plan-card-one">
                        <div className="card-one-header">
                            <TbWorldSearch fontSize={55} color="#007DD1"/>
                            <h2>{t("plan.h1")}</h2>        
                        </div>
                        <div className="card-one-text">
                            <p>{t("plan.p1")}</p>
                        </div>
                    </div>
                    <div className="plan-card-two">
                        <div className="card-one-header">
                            <TfiPencilAlt fontSize={55} color="#007DD1"/>  
                            <h2>{t("plan.h2")}</h2>                           
                        </div>
                        <div className="card-one-text">
                            <p>{t("plan.p2")}</p>
                        </div>
                    </div>
                    <div className="plan-card-three">
                        <div className="card-one-header">
                            <IoCallSharp fontSize={55} color="#007DD1"/> 
                            <h2>{t("plan.h3")}</h2>                           
                        </div>
                        <div className="card-one-text">
                            <p>{t("plan.p3")}</p>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default WorkingPlan;