import '../styles/Sort.css';
import { useState } from "react";
import { useTranslation } from 'react-i18next';

function Sort({ onSortChange }) {

  const [t, i18n] = useTranslation("global");

  const [sortOption, setSortOption] = useState("");

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortOption(value);
    onSortChange(value);
  };

  return (
    <div className="sort-body">
      <div className="sort-container">
        <select name="sort" value={sortOption} onChange={handleSortChange}>
          <option value="low-to-high">{t("sort.text1")}</option>
          <option value="high-to-low">{t("sort.text2")}</option>
          <option value="a-to-z">{t("sort.text3")}</option>
          <option value="z-to-a">{t("sort.text4")}</option>
        </select>
      </div>
    </div>
  );
}

export default Sort;
