import React from 'react';
import '../styles/Loader.css';
import Logo from '../assets/loaderLogo.webp';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-body">
        <img src={Logo} alt="Loading..." className="loader-logo" />
      </div>
    </div>
  );
};

export default Loader;