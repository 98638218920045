import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Tours.css";
import { useTranslation } from "react-i18next";

function Tours() {
  const { t } = useTranslation("global");

  const navigate = useNavigate();

  const handleReadMore = (destTitle) => {
    switch (destTitle.toLowerCase()) {
      case 'tours':
        navigate('/all-tours');
        break;
      case 'experience':
        navigate('/experience');
        break;
      case 'about us':
        navigate('/about');
        break;
      case 'ტურები':
        navigate('./all-tours');
        break;
      case "გამოცდილება": 
        navigate('/experience');
        break;
      case 'ჩვენს შესახებ':
        navigate('/about');
        break;  
      case 'туры': 
        navigate('/all-tours');
        break;
      case 'опыт': 
        navigate('/experience');
        break;
      case 'о нас': 
        navigate('/about');
        break;
      default:
        navigate('/');
        break;  
    }
  };

  return (
    <section className="tours-main">
      <div className="tours-card">
        {t("destinations", { returnObjects: true }).map((destination) => {
          const { id, imgSrc, destTitle, info} = destination;
          return (
            <div key={id} className="singleDestination">
              <div className="imageDiv">
                <img src={imgSrc} alt={destTitle} />
              </div>
              <div className="cardInfo">
                <h4 className="destTitle">{destTitle}</h4>
                <div className="dest-info">
                  <div className="info">
                    <h5>{info}</h5>
                  </div>
                </div>
                <div className="tours-btn-div">
                  <button
                    className="tours-btn"
                    onClick={() => handleReadMore(destTitle)}
                  >
                    {t("tours.text1")}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Tours;
