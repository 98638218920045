import '../styles/Review.css';
import ReviewData from '../db/ReviewData';

function Review(){
    return(
        <div className="review-body" data-aos="fade-up">
        <div className="review-container">
            {ReviewData.map((item) => (
                <div className={`review-item item-${item.index}`} key={item.index}>
                    <div className="review-header">
                        <img src={item.profileImg} alt="" />
                        <h3>{item.name}</h3>
                    </div>
                    <div className="review-text">
                        <p>{item.text}</p>
                    </div>
                </div>
            ))}
        </div>
        </div>
    );
}

export default Review;
