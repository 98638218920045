import { useState } from 'react';
import '../styles/Faq.css';
import Line from './Line';
import { useTranslation } from 'react-i18next';

function Faq() {

    const [t, i18n] = useTranslation("global");

    const faq = [
        {
            index: 1,
            question: t("faq.q1"),
            answer: t("faq.an1")
        },
        {
            index: 2,
            question: t("faq.q2"),
            answer: t("faq.an2"),
        },
        {
            index: 3,
            question: t("faq.q3"),
            answer: t("faq.an3")
        },
        {
            index: 4,
            question: t("faq.q4"),
            answer: t("faq.an4")
        },
    ];

    const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(null);

    function toggle(index) {
        if (visibleAnswerIndex === index) {
            setVisibleAnswerIndex(null);
        } else {
            setVisibleAnswerIndex(index);
        }
    }

    return (
        <div className="faq-container">
            {faq.map((item) => (
                <div key={item.index} className="faq-item">
                    <h3 className="faq-question" onClick={() => toggle(item.index)}>
                        {item.question}
                        <span className="faq-toggle">{visibleAnswerIndex === item.index ? '-' : '+'}</span>
                    </h3>
                    <p className={`faq-answer ${visibleAnswerIndex === item.index ? 'faq-answer-open' : ''}`}>
                        {item.answer}
                    </p>
                    <Line/>
                </div>
            ))}
        </div>
    );
}

export default Faq;
