import React from 'react';
import { Link } from 'react-router-dom'; 
import '../styles/Card.css';
import Badge from 'react-bootstrap/Badge';
import { CiClock2 } from "react-icons/ci";
import { useTranslation } from 'react-i18next';

function Card({ imgSrc, destTitle, info, price, type, badgeText, time }) {
  const formattedTitle = destTitle.toLowerCase().replace(/\s+/g, '-'); 

  const [t, i18n] = useTranslation("global");
  
  return (
    <Link to={`/tours/${formattedTitle}`} as={Link} className="card-link">
      <div className="card" data-aos="fade-up">
        <img src={imgSrc} alt={destTitle} className="card-image" />
        <div className="card-content">
          <div className='card-container-header'>
            <h3 className="card-title">{destTitle}</h3>
            <h3><Badge bg={`${type}`}>{badgeText}</Badge></h3>
          </div>
          <p className='card-time'><CiClock2 color='#007DD1' fontSize={25}/> {time}</p>
          <p className="card-info">{info}</p>
          <h5 className='card-price'>{t("card.from")} ${price}/{t("card.person")}</h5>
        </div>
      </div>
    </Link>
  );
}

export default Card;
