import { useState } from "react";
import "../styles/Sidefilter.css";
import Line from "../components/Line";
import ReactSlider from "react-slider";
import { useTranslation } from "react-i18next";

function Sidefilter({ onFilterChange }) {

  const [t, i18n] = useTranslation("global");

  const [priceRange, setPriceRange] = useState([0, 2000]);
  const [category, setCategory] = useState("");

  const handlePriceChange = (values) => {
    setPriceRange(values);
    onFilterChange({ priceRange: values, category });
  };

  const handlePriceFromChange = (event) => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    setPriceRange([value, priceRange[1]]);
    onFilterChange({ priceRange: [value, priceRange[1]], category });
  };

  const handlePriceToChange = (event) => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    setPriceRange([priceRange[0], value]);
    onFilterChange({ priceRange: [priceRange[0], value], category });
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setCategory(value);
    onFilterChange({ priceRange, category: value });
  };

  return (
    <div className="filter-body">
      <div className="price-filter-container">
        <div className="price-filter-header">
          <h4>{t("sideFilter.cost")}</h4>
        </div>
        <Line />
        <div className="price-filter-inside">
          <div className="price-filter-checkbox">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={[0, 2000]}
              min={0}
              max={2000}
              value={priceRange}
              onChange={handlePriceChange}
              pearling
              minDistance={10}
            />
          </div>
          <br />
          <div className="price-filter-from-to">
            <input
              type="number"
              placeholder="from"
              value={priceRange[0]}
              onChange={handlePriceFromChange}
            />
            <input
              type="number"
              placeholder="to"
              value={priceRange[1]}
              onChange={handlePriceToChange}
            />
          </div>
        </div>
      </div>
      <div className="categories-filter-container">
        <div className="categories-filter-header">
          <h4>{t("sideFilter.categories")}</h4>
        </div>
        <Line />
        <div className="categories-filter-checkbox">
          <div className="radio-item">
            <input
              type="radio"
              id="horse"
              name="tour"
              value="horse"
              checked={category === "horse"}
              onChange={handleCategoryChange}
            />
            <label htmlFor="horse">{t("sideFilter.horseTour")}</label>
          </div>
          <div className="radio-item">
            <input
              type="radio"
              id="regular"
              name="tour"
              value="regular"
              checked={category === "regular"}
              onChange={handleCategoryChange}
            />
            <label htmlFor="regular">{t("sideFilter.regularTour")}</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidefilter;
