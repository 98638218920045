import React from 'react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons';
import useEmblaCarousel from 'embla-carousel-react';
import Card from './Card';
import { useTranslation } from 'react-i18next';

const EmblaCarousel = (props) => {

  const [t, i18n] = useTranslation("global");

  const cardData = [
    {
      id: 1,
      imgSrc: "https://i.imgur.com/RVgMrh3.jpeg",
      destTitle: 'Lechxumi-Regular',
      info: t("tvishi.info"),
      price: 515,
      horseTour: false,
      time: `4-7 ${t("random.days")}`,
      category: "regular"
    },
    {
      id: 2,
      imgSrc: "https://i.imgur.com/67Atcz2.jpeg",
      destTitle: 'Zemo-Svaneti-regular',
      info: t("zemo-svaneti.info"),
      price: 515,
      horseTour: false,
      time: `4-7 ${t("random.days")}`,
      category: "regular"
    },
    {
      id: 3,
      imgSrc: "https://i.imgur.com/Uh8thec.jpeg",
      destTitle: 'Racha-regular',
      info: t("racha.info"),
      price: 515,
      horseTour: false,
      time: `4-7 ${t("random.days")}`,
      category: "regular"
    },
    {
      id: 4,
      imgSrc: "https://i.imgur.com/2qQGoe4.jpeg",
      destTitle: "Zemo-Svaneti-horse",
      info: t("zemo-svaneti-h.info"),
      price: 115,
      horseTour: true,
      time: `5-8 ${t("random.hours")}`,
      category: "horse"
    },
    {
      id: 5,
      imgSrc: "https://i.imgur.com/Cr6563y.jpeg",
      destTitle: 'Lechxumi-horse',
      info: t("lechxumi-h.info"),
      price: 115,
      horseTour: true,
      time: `5-8 ${t("random.hours")}`,
      category: "horse"
    },
    {
      id: 6,
      imgSrc: "https://i.imgur.com/7ZfACMd.jpeg",
      destTitle: 'Racha-horse',
      info: t("racha-h.info"),
      price: 115,
      horseTour: true,
      time: `5-8 ${t("random.hours")}`,
      category: "horse"
    }
  ];


  const { options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {cardData.map((data) => (
            <div className="embla__slide" key={data.id}>
              <Card 
                imgSrc={data.imgSrc} 
                destTitle={data.destTitle} 
                info={data.info} 
                time={data.time}
                price={data.price}
                type={data.horseTour ? 'warning' : 'success'}
                badgeText={data.horseTour ? 'Horse Tour' : 'Regular Tour'}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default EmblaCarousel;
