import React from 'react';
import { useParams } from 'react-router-dom';
import BackgroundLineVideo from '../assets/background-line.jpg';
import "../styles/WikiPage.css";
import { useTranslation } from "react-i18next";

function WikiPage(){
    const [t, i18n] = useTranslation("global");
    const { wikiTitle } = useParams();

    const wikiData = [
        {
            name: "/wiki/khvamli",
            header: t("exp.xvamli"),
            info: [
                t("wiki.xvamli-info0"),
                t("wiki.xvamli-info1"),
                t("wiki.xvamli-info2"),
                t("wiki.xvamli-info3"),
            ]
        },
        {
            name: "/wiki/winery",
            header: t("exp.winery"),
            info: [
                t("wiki.winery-info0"),
                t("wiki.winery-info1"),
                t("wiki.winery-info2"),
                t("wiki.winery-info3"),
                t("wiki.winery-info4"),
            ]
        },
        {
            name: "/wiki/cuisine",
            header: t("exp.cuisine"),
            info: [
                t("wiki.cuisine-info0"),
                t("wiki.cuisine-info1"),
                t("wiki.cuisine-info2"),
                t("wiki.cuisine-info3"),
            ]
        },
        {
            name: "/wiki/sataflia",
            header: t("exp.sataflia"),
            info: [
                t("wiki.sataflia-info0"),
                t("wiki.sataflia-info1"),
                t("wiki.sataflia-info2"),
                t("wiki.sataflia-info3"),
            ]
        },
        {
            name: "/wiki/prometheus-cave",
            header: t("exp.promete"),
            info: [
                t("wiki.promete-info0"),
                t("wiki.promete-info1"),
                t("wiki.promete-info2"),
            ]
        },
        {
            name: "/wiki/tvishi",
            header: t("exp.tvishi"),
            info: [
                t("wiki.tvishi-info0"),
                t("wiki.tvishi-info1"),
                t("wiki.tvishi-info2"),
                t("wiki.tvishi-info3"),
            ]
        }
    ];

    const wikiItem = wikiData.find(item => item.name === `/wiki/${wikiTitle}`);

    return (
        <>
            <div className="main-about-image">
                <img src="https://i.imgur.com/bT8WdVx.jpeg" alt="" className="about-main-image"/>
                <img src={BackgroundLineVideo} alt="background-line" className="about-else-image"/>
            </div>
            {wikiItem ? (
                <div className="main-about-body">
                    <div className="main-wiki-container">
                        <div className="wiki-header">
                            <h1>{wikiItem.header}</h1>
                        </div>
                        <div className="wiki-info">
                            <div className="wiki-text">
                                {wikiItem.info.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                            {wikiItem.img && (
                                <div className="wiki-image">
                                    <img src={wikiItem.img} alt={wikiItem.header} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <p>Wiki page not found.</p>
            )}
        </>
    );
}

export default WikiPage;
