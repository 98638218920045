const ReviewData = [
    {
        index: 0,
        profileImg: "https://i.imgur.com/rTWMJnG.png",
        name: "Alice Johnson",
        text: "An unforgettable adventure! The guides were professional and the scenery was breathtaking. Highly recommend Tvishi Tour for anyone looking to explore the natural beauty of Georgia."
    },
    {
        index: 1,
        profileImg: "https://i.imgur.com/08VR2Xa.png",
        name: "Michael Smith",
        text: "The horse riding tour was an amazing experience. The trails were beautiful, and the horses were well-trained and gentle. Highly recommended for nature lovers!"
    },
    {
        index: 2,
        profileImg: "https://i.imgur.com/lfvlBGt.png",
        name: "Ramaz Turashvili",
        text: "Tvishi Tour made our trip to Georgia truly special. The mountain views were stunning, and the guides were so knowledgeable and friendly. It was an experience we'll never forget."
    },
    {
        index: 3,
        profileImg: "https://i.imgur.com/kJMMOWt.png",
        name: "David Brown",
        text: "Fantastic tour with knowledgeable guides. The landscapes were out of this world, and every moment was well-organized and enjoyable. Tvishi Tour exceeded all our expectations!"
    },
    {
        index: 4,
        profileImg: "https://i.imgur.com/uSrRlCD.png",
        name: "Sophia Wilson",
        text: "A well-organized tour with breathtaking scenery. The guides were friendly and helpful, making sure we were comfortable and enjoying every part of the tour. Absolutely loved it!"
    },
    {
        index: 5,
        profileImg: "https://i.imgur.com/ZFJBPZz.png",
        name: "James Brown",
        text: "An incredible experience! The tour was well-paced and the views were unforgettable. The guides' expertise and passion for the area really shone through. Highly recommended!"
    },
];

export default ReviewData;
