import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import Navbar from './navbar/Navbar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Tourpage from './pages/Tourpage';
import Footer from './footer/Footer';
import Loading from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import Alltours from './pages/Alltours';
import { useTranslation } from 'react-i18next';
import Experience from './pages/Experience';
import WikiPage from './pages/WikiPage';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();

  const getLanguageClass = () => {
    switch (i18n.language) {
      case 'ge':
        return 'language-ge';
      case 'ru':
        return 'language-ru';
      default:
        return 'language-en';
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={getLanguageClass()}>
      <Navbar />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/tours/:tourTitle" element={<Tourpage />} />
        <Route path='/all-tours' element={<Alltours/>}/>
        <Route path='/experience' element={<Experience/>} />
        <Route path='/wiki/:wikiTitle' element={<WikiPage/>}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
