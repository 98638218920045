import "../styles/Text.css";

function Text({text}){
    return(
        <div className="main-body">
            <div className="main-text" data-aos="fade-up">
                <h1>{text}</h1>
            </div>
        </div>
    );
}

export default Text;