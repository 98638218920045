import '../styles/Main.css';
import Video from "../assets/busbridge.mp4";

function Main(){
    return(
        <div className='main-body'>
            <video src={Video} muted autoPlay loop type = "video/mp4" fluid></video>
            <div className="home-main">
                {/* <div className="home-main-text"><h1>Join us and enjoy the beauty of<br /> <span className="home-main-text-span">Georgia's nature</span></h1></div> */}
                {/* <div className="home-main-buttons">
                    <button className="tour-start">Start Tour</button>
                    <button>Contact Us</button>
                </div>  */}
            </div>
        </div>    
    );  
}

export default Main;