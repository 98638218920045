import React from "react";
import "../styles/Footer.css";

function Footer() {
    return (
        <div className="main-footer">
            <div className="footer-container">
                <div className="row">
                    <div className="col">
                        <h3>Policy</h3>
                        <ul className="list-unstyled">
                            <li>© 2024 TvishiTour</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>About Us</h4>
                        <ul className="list-unstyled">
                            <li>Website Rules</li>
                            <li>Policy/Privacy</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Contact</h4>
                        <ul className="list-unstyled">
                            <li>info@tvishitour.ge</li>
                            <li>+995 522 220 760</li>
                            <li>+995 599 162 755</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;