import "../styles/Home.css";
import WorkingPlan from "../components/WorkingPlan";
import Main from "../components/Main";
import BackgroundLine from "../components/BackgroundLine";
import Tours from "../components/Tours";
import Text from "../components/Text";
import EmblaCarouselFirst from "../components/EmblaCarouselFirst";
import '../styles/Embla.css';
import HomeAbout from "../components/HomeAbout";
import Review from "../components/Review";
import { useTranslation } from "react-i18next";

const OPTIONS = { align: 'center' }
const SLIDE_COUNT = 4
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

function Home(){

    const [t, i18n] = useTranslation("global");

    return(
        <section className="home-body">
            <Main/>
            <BackgroundLine/>
            <Tours/>
            <Text text={t("home.text1")}/>
            <EmblaCarouselFirst slides={SLIDES} options={OPTIONS}/>
            {/* <Text text="Horse tours"/> */}
            {/* <EmblaCarouselFirst slides={SLIDES} options={OPTIONS} badgeText="Horse Tours" type="warning"/> */}
            <Text text={t("home.text2")}/>
            <HomeAbout/>
            <Text text={t("home.text4")}/>
            <WorkingPlan/>
            <Text text={t("home.text5")}/>
            <Review/>
        </section>
    );
}

export default Home;