import "../styles/Contact.css";
import { useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from "react-i18next";

function Contact() {
    const [userData, setUserData] = useState({ Name: '', Email: '', Message: '' });
    const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

    const [t, i18n] = useTranslation("global");

    let name, value;
    const data = (e) => {   
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    };

    const send = async (e) => {
        e.preventDefault();
        const { Name, Email, Message } = userData;

        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(Email)) {
            setAlert({ show: true, message: t("contact.message1"), variant: 'danger' });
            return;
        }

        if (Message.length < 10) {
            setAlert({ show: true, message: t("contact.message2"), variant: 'danger' });
            return;
        }

        const option = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ Name, Email, Message })
        };

        const res = await fetch('https://tvishi-tour-default-rtdb.firebaseio.com/Messages.json', option);

        if (res.ok) {
            setAlert({ show: true, message: t("contact.message3"), variant: 'success' });
            setUserData({ Name: '', Email: '', Message: '' });
        } else {
            setAlert({ show: true, message: t("contact.message4"), variant: 'danger' });
        }
    };

    return (
        <div className="contact-body" >
            <div className="alert-container">
            {alert.show && (
                            <Alert variant={alert.variant} onClose={() => setAlert({ show: false, message: '', variant: '' })} dismissible>
                                {alert.message}
                            </Alert>
                        )}
            </div>
            <div className="contact-main">
                <h1 data-aos="fade-up">{t("contact.contact-us")}</h1>
                <div className="contact-inside" data-aos="fade-up">
                    <div className="contact-image">
                        <a href="https://www.google.com/maps/place/Nodar+Dumbadze+St,+Poti/@42.1447727,41.6697727,18z/data=!4m15!1m8!3m7!1s0x405db8fc90360b5b:0xf5e4b2a63afde7d2!2sNodar+Dumbadze+St,+Poti!3b1!8m2!3d42.1451671!4d41.6696365!16s%2Fg%2F11c1k968rm!3m5!1s0x405db8fc90360b5b:0xf5e4b2a63afde7d2!8m2!3d42.1451671!4d41.6696365!16s%2Fg%2F11c1k968rm?hl=en&entry=ttu">
                            <img src="https://i.imgur.com/gumgGkh.png" alt="location-img" />
                        </a>
                    </div>
                    <div className="contact-section">
                        <form className="contact-text-area" method="POST">
                            <input onChange={data} autoComplete="off" name="Email" type="email" value={userData.Email} className="email-input" placeholder={t("contact.placeholder1")}/>
                            <input onChange={data} autoComplete="off" name="Name" type="text" value={userData.Name} className="name-input" placeholder={t("contact.placeholder2")}/>
                            <textarea onChange={data} autoComplete="off" name="Message" value={userData.Message} placeholder={t("contact.placeholder3")}></textarea>
                            <button onClick={send}>{t("contact.submit")}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
