import "../styles/About.css";
import { GiHorseHead } from "react-icons/gi";
import Line from "../components/Line";
import Faq from "../components/Faq";
import BackgroundLineVideo from '../assets/background-line.jpg';
import { GiMountainRoad } from "react-icons/gi";
import { FaPlaneArrival } from "react-icons/fa";
import { IoFastFoodSharp } from "react-icons/io5";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

function About(){

    const [t, i18n] = useTranslation("global");

    return(
        <>
        <div className="main-about-image">
            <img src="https://i.imgur.com/bT8WdVx.jpeg" alt="" className="about-main-image"/>
            <img src={BackgroundLineVideo} alt="background-line" className="about-else-image"/>
        </div>
        <div className="main-about-body">
            <div className="main-about-container" data-aos="fade-up">
                <div className="main-about-header">
                    <h1>{t("about.main-header")}</h1>
                    <p>{t("about.main-welcome")}</p>
                    <p>{t("about.main-welcome-second")}</p>
                    <p>{t("about.main-welcome-third")}</p>
                </div>
                <div className="main-about-services" data-aos="fade-up">
                    <div className="about-services-text">
                        <h1>{t("about.main-services-header")}</h1>
                        <p className="services-text-main-p">{t("about.main-services-text")}</p>
                    </div>
                    <div className="about-services-system">
                        <div className="services-system-container">
                            <div className="system-container-header">
                                <GiMountainRoad className="horse"/>
                                <h3>{t("about.main-mountain-header")}</h3>
                            </div>
                            <div className="system-container-text">
                                <p>{t("about.main-mountain-text")}</p>
                            </div>
                            <Line/>
                        </div>
                        <div className="services-system-container">
                            <div className="system-container-header">
                                <GiHorseHead className="horse"/>
                                <h3>{t("about.main-horse-header")}</h3>
                            </div>
                            <div className="system-container-text">
                                <p>{t("about.main-horse-text")}</p>
                            </div>
                            <Line/>
                        </div>
                        <div className="services-system-container">
                        <div className="system-container-header">
                                <FaPlaneArrival className="horse"/>
                                <h3>{t("about.main-airport-header")}</h3>
                            </div>
                            <div className="system-container-text">
                                <p>{t("about.main-airport-text")}</p>
                            </div>
                            <Line/>
                        </div>
                        <div className="services-system-container">
                        <div className="system-container-header">
                                <IoFastFoodSharp className="horse"/>
                                <h3>{t("about.main-cuisine-header")}</h3>
                            </div>
                            <div className="system-container-text">
                                <p>{t("about.main-cuisine-text")}</p>
                            </div>
                            <Line/>
                        </div>
                    </div>
                </div>
                <div className="main-about-process" data-aos="fade-up">
                    <div className="about-process-header">
                        <h1>{t("about.main-work")}</h1>
                    </div>
                    <div className="about-process-system">
                        <div className="process-system-number">
                            <h3>01</h3>
                            <h3>{t("about.main-system-header")}</h3>
                        </div>
                        <div className="process-system-header">
                            <p>{t("about.main-system-text")}</p>
                            <Line/>
                        </div>
                        <div className="process-system-number">
                            <h3>02</h3>
                            <h3>{t("about.main-arrival-header")}</h3>
                        </div>
                        <div className="process-system-header">
                            <p>{t("about.main-arrival-text")}</p>
                            <Line/>
                        </div>
                        <div className="process-system-number">
                            <h3>03</h3>
                            <h3>{t("about.main-adventure-header")}</h3>
                        </div>
                        <div className="process-system-header">
                            <p>{t("about.main-adventure-text")}</p>
                            <Line/>
                        </div>
                        <div className="process-system-number">
                            <h3>04</h3>
                            <h3>{t("about.main-comfort-header")}</h3>
                        </div>
                        <div className="process-system-header">
                            <p>{t("about.main-comfort-text")}</p>
                        </div>
                    </div>
                </div>
                <div className="about-faq-container" data-aos="fade-up">
                    <h1>{t("about.main-precise-responses")}</h1>
                    <Faq/>
                </div>
                <div className="ceo-body" data-aos="fade-up">
                    <div className="ceo-header">
                        <h1>{t("about.main-ceo-header")}</h1>
                    </div>
                    <div className="ceo-container">
                        <div className="img">
                            <img src="https://i.imgur.com/Ru0T2ln.jpeg" alt="" />
                        </div>
                        <div className="ceo-text">
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default About;