import React from 'react';

const Thumb = (props) => {
    const { selected, index, onClick, imageSrc } = props;

    return (
        <div
            className={`embla-thumbs__slide-s ${selected ? ' embla-thumbs__slide--selected-s' : ''}`}
        >
            <button
                onClick={onClick}
                type="button"
                className="embla-thumbs__slide__number-s"
            >
                <img src={imageSrc} alt={`Thumbnail ${index}`} />
            </button>
        </div>
    );
};

export default Thumb; 
