import React from "react";
import "../styles/Experience.css";
import Line from "../components/Line";
import BackgroundLineVideo from '../assets/background-line.jpg';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Experience() {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    const expData = [
        {
            name: t("exp.xvamli"),
            imgSrc: "https://cdn.ambebi.ge/media/images/new_photo/dsc03103.jpg",
            link: "/wiki/khvamli"
        },
        {
            name: t("exp.winery"),
            imgSrc: "https://winiveria.ge/wp-content/uploads/2022/02/cover_vfLRFbu.jpg",
            link: "/wiki/winery"
        },
        {
            name: t("exp.cuisine"),
            imgSrc: "https://madloba.info/media/images/6Gruzinskaya_kukhnya.2e16d0ba.fill-1200x630.format-png.png",
            link: "/wiki/cuisine"
        },
        {
            name: t("exp.sataflia"),
            imgSrc: "https://cdn.georgiantravelguide.com/storage/files/sataflia-satafliis-aghkvetili-sataplia-9.jpg",
            link: "/wiki/sataflia"
        },
        {
            name: t("exp.promete"),
            imgSrc: "https://www.turebi.ge/uploads/photos/tours1/large/39632_1.jpg",
            link: "/wiki/prometheus-cave"
        },
        {
            name: t("exp.tvishi"),
            imgSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGqRQ50MQyxVdWwaGXXggU5a7sLmVilpi02w&s",
            link: "/wiki/tvishi"
        }
    ];

    const handleCardClick = (link) => {
        navigate(link);
    };

    return (
        <>
            <div className="main-exp-image">
                <img src="https://i.imgur.com/DTkAz6p.jpeg" alt="main" className="exp-main-image" />
                <img src={BackgroundLineVideo} alt="background-line" className="exp-else-image" />
            </div>
            <div className="main-exp-body" data-aos="fade-up">
                <div className="main-exp-container" data-aos="fade-up">
                    <div className="main-exp-header">
                        <h1>{t("exp.header")}</h1>
                        <p>{t("exp.info")}</p>
                    </div>
                    <div className="exp-cards">
                        {expData.map((item, index) => (
                            <div
                                data-aos="fade-up"
                                key={index}
                                className="exp-card"
                                onClick={() => handleCardClick(item.link)}
                            >
                                <img src={item.imgSrc} alt={item.name} className="exp-card-img" />
                                <h3>{item.name}</h3>
                            </div>
                        ))}
                    </div>
                    <div className="exp-advice">
                        <div className="sss">{t("exp.advice0")}</div> 
                        <div>
                            <span className="sss">{t("exp.advice1")}</span>
                        </div>
                    </div>
                    <div className="exp-why">
                        <div className="why-text" data-aos="fade-up">
                            <h1>{t("exp.why0")}</h1>
                            <p>{t("exp.why1")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Experience;
