import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Alltours.css";
import Sidefilter from "../components/Sidefilter";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sort from "../components/Sort";
import { CiClock2 } from "react-icons/ci";
import { useTranslation } from "react-i18next";

function Alltours() {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();

  const generateCardData = () => [
    {
      id: 1,
      imgSrc: "https://i.imgur.com/j4Swp2K.jpeg",
      destTitle: 'Lechxumi-Regular',
      info: t("alltours.tvishi"),
      price: 550,
      horseTour: false,
      time: "4-7 days",
      category: "regular"
    },
    {
      id: 2,
      imgSrc: "https://i.imgur.com/2qQGoe4.jpeg",
      destTitle: "Zemo-Svaneti-horse",
      info: t("alltours.svaneti"),
      price: 115,
      horseTour: true,
      time: "5-6 hours",
      category: "horse"
    },
    {
      id: 3,
      imgSrc: "https://i.imgur.com/Uh8thec.jpeg",
      destTitle: 'Racha-regular',
      info: t("alltours.racha"),
      price: 550,
      horseTour: false,
      time: "4-8 days",
      category: "regular"
    },
    {
      id: 4,
      imgSrc: "https://i.imgur.com/2qQGoe4.jpeg",
      destTitle: 'Zemo-Svaneti-regular',
      info: t("alltours.svaneti"),
      price: 550,
      horseTour: false,
      time: "4-7 days",
      category: "regular"
    },
    {
      id: 5,
      imgSrc: "https://i.imgur.com/Cr6563y.jpeg",
      destTitle: 'Lechxumi-horse',
      info: t("alltours.lechxumi"),
      price: 115,
      horseTour: true,
      time: "5-6 hours",
      category: "horse"
    },
    {
      id: 6,
      imgSrc: "https://i.imgur.com/7ZfACMd.jpeg",
      destTitle: 'Racha-horse',
      info: t("alltours.racha"),
      price: 115,
      horseTour: true,
      time: "4-6 hours",
      category: "horse"
    }
  ];

  const [cardData, setCardData] = useState(generateCardData);
  const [filteredData, setFilteredData] = useState(cardData);
  const [filters, setFilters] = useState({ priceRange: [0, 2000], category: "" });
  const [sortOption, setSortOption] = useState("");

  useEffect(() => {
    const { priceRange, category } = filters;
    let data = cardData.filter(card => 
      card.price >= priceRange[0] && card.price <= priceRange[1]
    );
    if (category) {
      data = data.filter(card => card.category === category);
    }
    setFilteredData(data);
  }, [filters, cardData]);

  useEffect(() => {
    let data = [...filteredData];
    if (sortOption === "low-to-high") {
      data.sort((a, b) => a.price - b.price);
    } else if (sortOption === "high-to-low") {
      data.sort((a, b) => b.price - a.price);
    } else if (sortOption === "a-to-z") {
      data.sort((a, b) => a.destTitle.localeCompare(b.destTitle));
    } else if (sortOption === "z-to-a") {
      data.sort((a, b) => b.destTitle.localeCompare(a.destTitle));
    }
    setFilteredData(data);
  }, [sortOption, filteredData]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setCardData(generateCardData()); 
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const handleFilterChange = (filters) => {
    setFilters(filters);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  const handleCardClick = (tourTitle) => {
    navigate(`/tours/${tourTitle}`);
  };

  return (
    <div className="all-tours-body">
      <div className="all-tours-container">
        <div className="crumb" data-aos="fade-up">
          <Breadcrumb>
            <Breadcrumb.Item href="/">{t("alltours.breadcrumb-text1")}</Breadcrumb.Item>
            <Breadcrumb.Item href="/tours">{t("alltours.breadcrumb-text2")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("alltours.breadcrumb-text3")}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Sort onSortChange={handleSortChange} />
        <div className="all-tours-main">
          <Sidefilter onFilterChange={handleFilterChange} />
          <div className="all-tours">
            {filteredData.map((card) => (
              <div 
                key={card.id} 
                className="all-tours-card" 
                onClick={() => handleCardClick(card.destTitle.replace(/\s+/g, '-').toLowerCase())}
              >
                <img src={card.imgSrc} alt={card.destTitle} />
                <div className="all-tours-card-inside">
                  <h3>{card.destTitle}</h3>
                  <p className="card-time">
                    <CiClock2 fontSize={25} /> {card.time}
                  </p>
                  <p>{card.info}</p>
                  <h4>{t("alltours.price")} ${card.price}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alltours;
