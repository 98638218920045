import { useParams } from "react-router-dom";
import "../styles/Tourpage.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Line from "../components/Line";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoLanguageSharp } from "react-icons/io5";
import { FaCarSide } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import EmblaCarousel from "../components/EmblaCarouselSecond";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CiClock2 } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OPTIONS = {};
const SLIDE_COUNT = 15;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

function Tourpage() {

  const [t, i18n] = useTranslation("global");

  const cardData = [
    {
      id: 1,
      imgSrc: "https://i.imgur.com/RVgMrh3.jpeg",
      img0: "https://i.imgur.com/AhiXzp3.jpeg",
      img1: "https://i.imgur.com/fLGhrxV.jpeg",
      img2: "https://i.imgur.com/O9ovrih.jpeg",
      img3: "https://i.imgur.com/Bxthadq.jpeg",
      img4: "https://i.imgur.com/uRvOKrt.jpeg",
      img5: "https://i.imgur.com/j4Swp2K.jpeg",
      img6: "https://i.imgur.com/JGanIc0.jpeg",
      img7: "https://i.imgur.com/tQV6l8a.jpeg",
      img8: "https://i.imgur.com/Cr6563y.jpeg",
      destTitle: 'Lechxumi-Regular',
      info: t("tvishi.info"),
      activities: {
        day1: {
          type: "Cost included",
          header: t("tvishi.day1-header"),
          title: t("tvishi.day1-title"),
          text1: t("tvishi.day1-text1"),
          text2: t("tvishi.day1-text2"),
          text3: t("tvishi.day1-text3"),
        },
        day2: {
          type: "Cost included",
          header: t("tvishi.day2-header"),
          title: t("tvishi.day2-title"),
          text1: t("tvishi.day2-text1"),
          text2: t("tvishi.day2-text2"),
          text3: t("tvishi.day2-text3"),
          text4: t("tvishi.day2-text4"),
          text5: t("tvishi.day2-text5"),
          text6: t("tvishi.day2-text6")
        },
        day3: {
          type: "Cost included",
          header: t("tvishi.day3-header"),
          title: t("tvishi.day3-title"),
          text1: t("tvishi.day3-text1"),
          text2: t("tvishi.day3-text2"),
          text3: t("tvishi.day3-text3")
        },
        day4: {
          type: "Cost included",
          header: t("tvishi.day4-header"),
          title: t("tvishi.day4-title"),
          text1: t("tvishi.day4-text1"),
          text2: t("tvishi.day4-text2"),
          text3: t("tvishi.day4-text3"),
          text4: t("tvishi.day4-text4"),
        },
        dayElse: {
          type: "Cost excluded",
          header: t("tvishi.dayelse-header"),
          title: t("tvishi.dayelse-title"),
          text1: t("tvishi.dayelse-text1"),
          text2: t("tvishi.dayelse-text2"),
          text3: t("tvishi.dayelse-text3"),
          text4: t("tvishi.dayelse-text4"),
        }
      },
      humans: "4-7",
      price: 515,
      horseTour: false,
      time: "4-7 days",
      category: "regular"
    },
    {
      id: 2,
      imgSrc: "https://i.imgur.com/MF7pcLQ.jpeg",
      img0: "https://i.imgur.com/AU3QroU.jpeg",
      img1: "https://i.imgur.com/r3Mwc99.jpeg",
      img2: "https://i.imgur.com/OWd7yWi.jpeg",
      img3: "https://i.imgur.com/ilOlEcl.jpeg",
      img4: "https://i.imgur.com/hF2aF4C.jpeg",
      img5: "https://i.imgur.com/67Atcz2.jpeg",
      img6: "https://i.imgur.com/AU3QroU.jpeg",
      img7: "https://i.imgur.com/aWHzwfx.jpeg",
      img8: "https://i.imgur.com/pkK7U1u.jpeg",
      img9: "https://i.imgur.com/biMaHhd.jpeg",
      img10: "https://i.imgur.com/iwXfR1x.jpeg",
      destTitle: 'Zemo-Svaneti-regular',
      info: t("zemo-svaneti.info"),
      activities: {
        day1: {
          type: "Cost included",
          header: t("zemo-svaneti.day1-header"),
          title: t("zemo-svaneti.day1-title"),
          text1: t("zemo-svaneti.day1-text1"),
          text2: t("zemo-svaneti.day1-text2"),
          text3: t("zemo-svaneti.day1-text3"),
        },
        day2: {
          type: "Cost included",
          header: t("zemo-svaneti.day2-header"),
          title: t("zemo-svaneti.day2-title"),
          text1: t("zemo-svaneti.day2-text1"),
          text2: t("zemo-svaneti.day2-text2"),
          text3: t("zemo-svaneti.day2-text3"),
          text4: t("zemo-svaneti.day2-text4"),
          text5: t("zemo-svaneti.day2-text5"),
          text6: t("zemo-svaneti.day2-text6")
        },
        day3: {
          type: "Cost included",
          header: t("zemo-svaneti.day3-header"),
          title: t("zemo-svaneti.day3-title"),
          text1: t("zemo-svaneti.day3-text1"),
          text2: t("zemo-svaneti.day3-text2"),
          text3: t("zemo-svaneti.day3-text3")
        },
        day4: {
          type: "Cost included",
          header: t("zemo-svaneti.day4-header"),
          title: t("zemo-svaneti.day4-title"),
          text1: t("zemo-svaneti.day4-text1"),
          text2: t("zemo-svaneti.day4-text2"),
          text3: t("zemo-svaneti.day4-text3"),
          text4: t("zemo-svaneti.day4-text4"),
        },
        dayElse: {
          type: "Cost excluded",
          header: t("zemo-svaneti.dayelse-header"),
          title: t("zemo-svaneti.dayelse-title"),
          text1: t("zemo-svaneti.dayelse-text1"),
          text2: t("zemo-svaneti.dayelse-text2"),
          text3: t("zemo-svaneti.dayelse-text3"),
          text4: t("zemo-svaneti.dayelse-text4"),
        }
      },
      humans: "4-7",
      price: 515,
      horseTour: true,
      time: "4-7 days",
      category: "regular"
    },
    {
      id: 3,
      imgSrc: "https://i.imgur.com/Uh8thec.jpeg",
      img0: "https://i.imgur.com/J7ovoW2.jpeg",
      img1: "https://i.imgur.com/3M9e3Tr.png",
      img2: "https://i.imgur.com/Mgks21N.png",
      img3: "https://i.imgur.com/fKOXAHV.png",
      img4: "https://i.imgur.com/n4jF6PY.png",
      img5: "https://i.imgur.com/eGQx5D6.png",
      img6: "https://i.imgur.com/8cNwVof.png",
      img7: "https://i.imgur.com/YztEZD1.png",
      img8: "https://i.imgur.com/OWzg9S1.png",
      img9: "https://i.imgur.com/OnmUTK0.png",
      img10: "https://i.imgur.com/HHVe1mO.png",
      destTitle: 'Racha-regular',
      info: t("racha.info"),
      activities: {
        day1: {
          type: "Cost included",
          header: t("racha.day1-header"),
          title: t("racha.day1-title"),
          text1: t("racha.day1-text1"),
          text2: t("racha.day1-text2"),
          text3: t("racha.day1-text3"),
        },
        day2: {
          type: "Cost included",
          header: t("racha.day2-header"),
          title: t("racha.day2-title"),
          text1: t("racha.day2-text1"),
          text2: t("racha.day2-text2"),
          text3: t("racha.day2-text3"),
          text4: t("racha.day2-text4"),
          text5: t("racha.day2-text5"),
          text6: t("racha.day2-text6")
        },
        day3: {
          type: "Cost included",
          header: t("racha.day3-header"),
          title: t("racha.day3-title"),
          text1: t("racha.day3-text1"),
          text2: t("racha.day3-text2"),
          text3: t("racha.day3-text3")
        },
        day4: {
          type: "Cost included",
          header: t("tvishi.day4-header"),
          title: t("racha.day4-title"),
          text1: t("racha.day4-text1"),
          text2: t("racha.day4-text2"),
          text3: t("racha.day4-text3"),
          text4: t("racha.day4-text4"),
        },
        dayElse: {
          type: "Cost excluded",
          header: t("racha.dayelse-header"),
          title: t("racha.dayelse-title"),
          text1: t("racha.dayelse-text1"),
          text2: t("racha.dayelse-text2"),
          text3: t("racha.dayelse-text3"),
          text4: t("racha.dayelse-text4"),
        }
      },
      humans: "4-7",
      price: 515,
      horseTour: false,
      time: "4-7 days",
      category: "regular"
    },
    {
      id: 4,
      imgSrc: "https://i.imgur.com/2qQGoe4.jpeg",
      img0: "https://i.imgur.com/DTkAz6p.jpeg",
      img1: "https://i.imgur.com/xrKVGMX.jpeg",
      img2: "https://i.imgur.com/i4m4B6Q.jpeg",
      img3: "https://i.imgur.com/gKH0FsY.jpeg",
      img4: "https://i.imgur.com/6LruDfV.jpeg",
      img5: "https://i.imgur.com/r9a3ygX.jpeg",
      img6: "https://i.imgur.com/96hUAvn.jpeg",
      img7: "https://i.imgur.com/Uh9ytza.jpeg",
      img8: "https://i.imgur.com/rJ2KvQ1.jpeg",
      img9: "https://i.imgur.com/B8KWY5h.jpeg",
      img10: "https://i.imgur.com/fdWIBGx.jpeg",
      destTitle: "Zemo-Svaneti-horse",
      info: t("zemo-svaneti-h.info"),
      activities: {
        day1: {
          type: "Cost included",
          header: t("zemo-svaneti-h.day1-header"),
          title: t("zemo-svaneti-h.day1-title"),
          text1: t("zemo-svaneti-h.day1-text1"),
          text2: t("zemo-svaneti-h.day1-text2")
        },
        day2: {
          type: "Cost included",
          header: t("zemo-svaneti-h.day2-header"),
          title: t("zemo-svaneti-h.day2-title"),
          text1: t("zemo-svaneti-h.day2-text1")
        },
        day3: {
          type: "Cost excluded",
          header: t("zemo-svaneti-h.day3-header"),
          title: t("zemo-svaneti-h.day3-title"),
          text1: t("zemo-svaneti-h.day3-text1"),
        },
        day4: {
          type: "Cost excluded",
          header: t("zemo-svaneti-h.day4-header"),
          title: t("zemo-svaneti-h.day4-title"),
          text1: t("zemo-svaneti-h.day4-text1")
        },
      },
      humans: "2+",
      price: 115,
      horseTour: false,
      time: "5-8 hours",
      category: "horse"
    },
    {
      id: 5,
      imgSrc: "https://i.imgur.com/Cr6563y.jpeg",
      img0: "https://i.imgur.com/JGanIc0.jpeg",
      img1: "https://i.imgur.com/O9ovrih.jpeg",
      img2: "https://i.imgur.com/dEohKHk.jpeg",
      img3: "https://i.imgur.com/QczDUKz.jpeg",
      img4: "https://i.imgur.com/0P8NoiM.jpeg",
      img5: "https://i.imgur.com/Z6dBsfC.jpeg",
      img6: "https://i.imgur.com/sCMtKRv.jpeg",
      img7: "https://i.imgur.com/LIbRBFG.jpeg",
      img8: "https://i.imgur.com/EO3bwbo.jpeg",
      img9: "https://i.imgur.com/FueGYbn.jpeg",
      destTitle: 'Lechxumi-horse',
      info: t("lechxumi-h.info"),
      activities: {
        day1: {
          type: "Cost included",
          header: t("lechxumi-h.day1-header"),
          title: t("lechxumi-h.day1-title"),
          text1: t("lechxumi-h.day1-text1"),
          text2: t("lechxumi-h.day1-text2")
        },
        day2: {
          type: "Cost included",
          header: t("lechxumi-h.day2-header"),
          title: t("lechxumi-h.day2-title"),
          text1: t("lechxumi-h.day2-text1")
        },
        day3: {
          type: "Cost excluded",
          header: t("lechxumi-h.day3-header"),
          title: t("lechxumi-h.day3-title"),
          text1: t("lechxumi-h.day3-text1"),
        },
        day4: {
          type: "Cost excluded",
          header: t("lechxumi-h.day4-header"),
          title: t("lechxumi-h.day4-title"),
          text1: t("lechxumi-h.day4-text1")
        },
      },
      humans: "2+",
      price: 115,
      horseTour: true,
      time: "5-8 hours",
      category: "horse"
    },
    {
      id: 6,
      imgSrc: "https://i.imgur.com/7ZfACMd.jpeg",
      img0: "https://i.imgur.com/hslhcOM.jpeg",
      img1: "https://i.imgur.com/nOAfm59.jpeg",
      img2: "https://i.imgur.com/wxcCfGC.png",
      img3: "https://i.imgur.com/fGdwpYw.png",
      img4: "https://i.imgur.com/p8wQWeb.png",
      img5: "https://i.imgur.com/ZYLvm9y.png",
      img6: "",
      img7: "",
      img8: "",
      img9: "",
      img10: "",
      destTitle: 'Racha-horse',
      info: t("racha-h.info"),
      activities: {
        day1: {
          type: "Cost included",
          header: t("racha-h.header"),
          title: t("racha-h.day1-title"),
          text1: t("racha-h.day1-text1"),
          text2: t("racha-h.day1-text2")
        },
        day2: {
          type: "Cost included",
          header: t("racha-h.day2-header"),
          title: t("racha-h.day2-title"),
          text1: t("racha-h.day2-text1")
        },
        day3: {
          type: "Cost excluded",
          header: t("racha-h.day3-header"),
          title: t("racha-h.day3-title"),
          text1: t("racha-h.day3-text1"),
        },
        day4: {
          type: "Cost excluded",
          header: t("racha-h.day4-header"),
          title: t("racha-h.day4-title"),
          text1: t("racha-h.day4-text1")
        },
      },
      humans: "2+",
      price: 115,
      horseTour: true,
      time: "5-8 hours",
      category: "horse"
    }
  ];

  const [randomCards, setRandomCards] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPage = location.pathname.split("/").pop().toLowerCase();

    const filteredCards = cardData.filter((card) => card.destTitle.toLowerCase() !== currentPage);

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const shuffledCards = shuffleArray([...filteredCards]).slice(0, 3);
    setRandomCards(shuffledCards);
  }, [location]);

  const handleCardClick = (destTitle) => {
    navigate(`/tours/${destTitle.toLowerCase()}`);
  };

  const { tourTitle } = useParams();

  const tourData = cardData.find((card) => card.destTitle.toLowerCase() === tourTitle.toLowerCase());

  if (!tourData) {
    return <div>Tour not found</div>;
  }

  const { destTitle, price, imgSrc, time, activities, humans, img0, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10 } = tourData;

  const slides = [imgSrc, img0, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10].filter(Boolean);

  return (
    <div className="tour-page-body">
      <div className="tour-container">
        <div className="crumb" data-aos="fade-up">
          <Breadcrumb>
            <Breadcrumb.Item href="/">{t("tourpage.breadcrumb-text1")}</Breadcrumb.Item>
            <Breadcrumb.Item href="/tours">{t("tourpage.breadcrumb-text2")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{destTitle}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="tour-main">
          <div className="tour-text-section">
            <div className="text-section-header" data-aos="fade-up">
              <h1>
                {t("tourpage.tourin")} <span>{destTitle}</span>
              </h1>
              <h2>
                <Badge bg="success">{time}</Badge>
              </h2>
            </div>
            <EmblaCarousel slides={slides} options={OPTIONS} />
            <div className="text-section-map">
              {Object.keys(activities).map((dayKey) => (
                <div className={`day-${dayKey}`} key={dayKey} data-aos="fade-up">
                  <div className="div-else-header">
                    <h2>
                      <span className="day-text-red">{activities[dayKey].header}: </span>
                      {activities[dayKey].title}
                    </h2>
                    <Badge bg={activities[dayKey].type === "Cost included" ? "success" : "danger"}>
                      <h5>{activities[dayKey].type === "Cost included" ? "Cost included" : "Cost excluded"}</h5>
                    </Badge>
                  </div>
                  <ul>
                    {Object.keys(activities[dayKey])
                      .filter((key) => key.startsWith("text"))
                      .map((textKey) => (
                        <li key={textKey}>{activities[dayKey][textKey]}</li>
                      ))}
                  </ul>
                </div>
              ))}
              <Line />
              <div className="highlight" data-aos="fade-up">
                <h2>{t("tourpage.highlight-header")}</h2>
                <p>
                  {t("tourpage.highlight-text")}
                </p>
              </div>
              <Line />
              <div className="rules" data-aos="fade-up">
                <h2>{t("tourpage.rules-header")}</h2>
                <p>
                  {t("tourpage.rules-text")}
                </p>
                <p>
                  {t("tourpage.ceo")}
                </p>
              </div>
              <Line />
              <div className="sight-info" data-aos="fade-up">
                <div className="city">
                  <h2>{t("tourpage.cities-header")}</h2>
                  <p>
                   {t("tourpage.cities-text")}
                  </p>
                </div>
                <div className="Khvamli">
                  <h2>{t("tourpage.khvamli")}</h2>
                  <p>
                    {t("tourpage.khvamli-text")}
                  </p>
                </div>
                <div className="else">
                  <h2>{t("tourpage.cuisine-header")}</h2>
                  <p>
                    {t("tourpage.cuisine-text")}
                  </p>
                </div>
                <div className="prometheus">
                  <h2>{t("tourpage.prometheus-header")}</h2>
                  <p>
                    {t("tourpage.prometheus-text")}
                  </p>
                </div>
                <div className="sataflia">
                  <h2>{t("tourpage.sataflia-header")}</h2>
                  <p>
                    {t("tourpage.sataflia-text")}
                  </p>
                </div>
              </div>
              <Line />
              <div className="attention" data-aos="fade-up">
                <h2>{t("tourpage.contact-header")}</h2>
                <p>{t("tourpage.contact-text2")}</p>
              </div>
              <Line />
              <h2 data-aos="fade-up">{t("tourpage.other-tours")}</h2>
              <div className="other-tours" data-aos="fade-up">
                {randomCards.map((card) => (
                  <div key={card.id} className="tourpage-card" onClick={() => handleCardClick(card.destTitle)}>
                    <img src={card.imgSrc} alt={card.destTitle} />
                    <h3>{card.destTitle}</h3>
                    <p className="card-time">
                      <CiClock2 fontSize={25} /> {card.time}
                    </p>
                    <p>{card.info}</p>
                    <h4>{t("tourpage.price")}: ${card.price}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="tour-price-section" data-aos="fade-up">
            <div className="price-section-price">
              <div className="price-section-header">
                <p>{t("tourpage.from")}</p>
                <h4>${price}</h4>
                <p>18+</p>
              </div>
              <div className="price-section-btn">
                <Link to="payment"><button>{t("tourpage.soon")}</button></Link>
              </div>
              <Line />
              <div className="price-section-help">
                <p>
                  {t("tourpage.help")} <a href="">{t("tourpage.send")}</a>
                </p>
              </div>
            </div>
            <div className="price-section-etc">
              <div className="group">
                <div className="group-text-h">
                  <FaPeopleGroup fontSize={25} color="#007DD1" />
                  <p>{t("tourpage.group")}</p>
                </div>
                <div className="group-text-p">
                  <p>{humans} {t("tourpage.human")}</p>
                </div>
              </div>
              <Line />
              <div className="transport">
                <div className="transport-text-h">
                  <FaCarSide fontSize={25} color="#007DD1" />
                  <p>{t("tourpage.transportation-header")}</p>
                </div>
                <div className="transport-text-p">
                  <p>{t("tourpage.transportation-text")}</p>
                </div>
              </div>
              <Line />
              <div className="duration">
                <div className="duration-text-h">
                  <FaClock fontSize={25} color="#007DD1" />
                  <p>{t("tourpage.duration")}</p>
                </div>
                <div className="duration-text-p">
                  <p>{time}</p>
                </div>
              </div>
              <Line />
              <div className="languages">
                <div className="languages-text-h">
                  <IoLanguageSharp fontSize={25} color="#007DD1" />
                  <p>{t("tourpage.language")}</p>
                </div>
                <div className="languages-text-p">
                  <p>{t("tourpage.lans")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tourpage;
